import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileScreenButton, faGlobe, faUserGear } from '@fortawesome/free-solid-svg-icons'

const VillkorPage = () => {
	return (
		<Layout pageTitle="Villkor &amp; policies">

            <p>Välkommen tillbaka!</p>

		</Layout>
	)
}

export default VillkorPage